/**
Prefix ad unit paths with TED's DFP Network ID.
*/
export const buildAdPath = (subpath: string) =>
  `/5641${subpath[0] === '/' ? subpath : `/${subpath}`}`;

/**
Turns a one-dimensional size array into `{width}x{height}`
Returns '0x0' if the size is unfathomable.
*/
export const stringifySize = (size: string | number[] | null) =>
  size && size.length === 2 ? `${size[0]}x${size[1]}` : '0x0';

/**
Simple single-topic pub/sub utility.
Returns `publish` and `subscribe` methods.
The `subscribe` method returns an unsubscribe callback.
*/

type PubSubCallback = (message?: string) => void;

export const createPublisher = () => {
  const callbacks: PubSubCallback[] = [];

  function publish(message: string) {
    callbacks.forEach(callback => callback(message));
  }

  function subscribe(callback: PubSubCallback) {
    callbacks.push(callback);

    return function unsubscribe() {
      callbacks.splice(callbacks.indexOf(callback), 1);
    };
  }

  return { publish, subscribe };
};
