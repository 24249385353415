/**
 * This utility loads, initializes, and returns the GPT ad library.
 * It's separated from other utilities because it must be mocked.
 */
import { executeGPTCommand, isGPTReady } from 'components/ads/gpt-script';

/**
 * Ensures that the code is executed in a browser environment.
 * Throws an error if window is not defined.
 */
export function checkEnv(): void {
  if (typeof window === 'undefined') {
    throw new Error(
      'Web ads cannot be rendered outside the browser. ' +
        'Using GPT during SSR breaks ads. ' +
        'Please use dynamic imports.'
    );
  }
}

// Cache the GPT loading promise so multiple calls share the same instance.
let gptLoadPromise: Promise<NonNullable<Window['googletag']>> | null = null;

/**
 * Returns a promise that resolves with the GPT library when it's ready.
 * If GPT doesn't signal readiness within the specified timeout,
 * the promise rejects. Might be overkill because GPT is usually ready
 * quickly, but it's better to be safe.
 *
 * @param timeout - Milliseconds to wait before rejecting (default 10000 ms)
 */
export function getGPT(
  timeout: number = 10000
): Promise<NonNullable<Window['googletag']>> {
  checkEnv();

  // If GPT is already ready, resolve immediately.
  if (isGPTReady()) {
    return Promise.resolve(window.googletag!);
  }

  // If a loading promise already exists, return it.
  if (gptLoadPromise) {
    return gptLoadPromise;
  }

  // Otherwise, create a new promise that waits for GPT to signal readiness.
  gptLoadPromise = new Promise((resolve, reject) => {
    // Move timeoutId to an outer scope so it can be accessed by the command callback
    const timeoutId = setTimeout(() => {
      gptLoadPromise = null;
      reject(new Error('GPT did not load within the specified timeout.'));
    }, timeout);

    executeGPTCommand(() => {
      clearTimeout(timeoutId);
      // Reset the cached promise after successful resolution
      gptLoadPromise = null;
      resolve(window.googletag!);
    });
  });

  return gptLoadPromise;
}
