/**
This library exists to help GPT cope in React/SPA environments.

The gist:
Ad components use the getSpot method instead of defining slots
directly. If the slot's definition already exists, then its
container can be moved to the new component and refreshed.

FAQ:
Q: What's a "handle"?
A: It's an identifier for Spots. It can be any string.
Q: What's a "Spot"?
A: A container object for a GPT slot + slot container
   indexed/identified by a "handle" string in the lot.
Q: Why don't you just use destroySlot in useEffect cleanups?
A: GPT's destroySlot method triggers warnings in the Google
   Publisher Console, which causes problems for support.
*/

import { getGPT } from './gpt';
import { createPublisher, stringifySize } from './utils';

type Spot = {
  slot: googletag.Slot;
  container: HTMLElement;
  displayed?: boolean;
};
// The library of previously defined spots, indexed by handle
const lot: {
  [handle: string]: Spot;
} = {};

// Find a spot in the lot by its GPT slot
function findBySlot(slot: googletag.Slot) {
  return Object.values(lot).find(spot => spot.slot === slot);
}

// See initFillChangePublisher: fill changes are published here
// TODO: Fix any
let fillChangePublisher;

// Use the GPT DFP service's global slotRenderEnded listener to
// create fillChangePublisher and publish when a spot's filled
async function initFillChangePublisher() {
  if (fillChangePublisher) return;

  fillChangePublisher = createPublisher();

  const googletag = await getGPT();
  googletag
    .pubads()
    .addEventListener(
      'slotRenderEnded',
      (event: googletag.events.SlotRenderEndedEvent) => {
        // Early escape for empty/non-slot events
        if (!event || !event.slot || event.isEmpty) return;

        // Escape for visually empty ads (house blocking ads, etc.)
        const stringySize = stringifySize(event.size);
        if (stringySize === '1x1' || stringySize === '0x0') return;

        // Find the rendered SlotSpot
        const spot = findBySlot(event.slot);

        // Let the SlotSpot know it's been filled
        fillChangePublisher.publish({ spot, isFilled: true });
      }
    );
}

// Used internally by the getSpot method to build new spots
async function buildSpot(
  handle: string,
  definer: (input: { containerId: string }) => googletag.Slot
) {
  await initFillChangePublisher();

  const container = document.createElement('div');
  const containerId = `ad-${Math.floor(Math.random() * 2 ** 52 - 1).toString(
    36
  )}`;
  container.setAttribute('id', containerId);

  const slot = definer({ containerId });

  const spot = { slot, container };
  return spot;
}

/**
If the spot identified by the handle is already defined, returns it.
Otherwise, uses the definer to define a new spot and return it.
(The definer should be a method that returns a GPT slot definition.)
*/
export async function getSpot(
  handle: string,
  definer: (input: { containerId: string }) => googletag.Slot
) {
  if (!lot[handle]) {
    lot[handle] = await buildSpot(handle, definer);
  }

  return lot[handle];
}

/**
Does a GPT display/refresh for the slot (whichever is appropriate).
*/
export async function showSpot(spot: Spot, parent: HTMLElement | null) {
  // TODO: Need to add a gaurd here as parent can be null or enforce it above in the chain
  if (parent) parent.appendChild(spot.container);

  const googletag = await getGPT();

  if (spot.displayed) googletag.pubads().refresh([spot.slot]);
  else googletag.display(spot.container.id);
  // TODO: Fix this as its really bad that we're mutating a param... We should create a getter/setter in the original Spot object

  spot.displayed = true;
}

/**
Subscribe to fill changes for the given spot.
Pass the spot and the subscriber callback.
An "isFilled" boolean is included each time the subscriber is called.
Returns an unsubscriber method.
*/
export async function subSpot(
  spot: Spot,
  callback: (newFilled: boolean) => void
) {
  await initFillChangePublisher();

  return fillChangePublisher.subscribe(
    (message: { spot: Spot; isFilled: boolean }) => {
      if (message.spot !== spot) return;
      callback(message.isFilled);
    }
  );
}
